<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useCartStore } from '~/stores/cart';

const cartStore = useCartStore();
const authStore = useAuthStore();

const { promocode } = storeToRefs(cartStore);

const submitPromocode = () => {
    if (!authStore.user) {
        promocode.value = null;
        navigateTo('/auth/login');
        return;
    }

    cartStore.fetch();
};

const clearPromocode = () => {
    promocode.value = null;

    cartStore.fetch();
};

const couponInfo = computed(() => {
    if (cartStore.cart?.coupon?.code == null) {
        return null;
    }

    let description = cartStore.cart?.coupon?.description ?? null;
    let steps = cartStore.cart?.coupon?.steps ?? null;

    if (steps != null && steps.length == 0) {
        steps = null;
    }

    if (description || steps) {
        return {
            description,
            steps
        };
    }

    return null;
});
</script>

<template>
    <form @submit.prevent="submitPromocode()">
        <div class="promocode-input"
            :class="{ 'with-info': couponInfo != null && (couponInfo['description'] != null || couponInfo['steps']) }">
            <input v-model="promocode" type="text" class="form-control" placeholder="Промокод"
                :readonly="cartStore.cart?.coupon?.code != null" />
            <button type="submit" v-if="cartStore.cart?.coupon?.code == null" class="promocode-input__button"
                :class="{ active: cartStore.cart?.coupon?.code != null }">
                <div>
                    Применить
                </div>
            </button>
            <button v-else class="promocode-input__trash" @click.prevent="clearPromocode()">
                <UiIconsTrashIcon />
            </button>
        </div>
        <div v-if="couponInfo != null" class="promocode-info">
            <div v-if="couponInfo.description" class="promocode-info__description">
                {{ couponInfo.description }}
            </div>
            <div v-for="step in (couponInfo['steps'] ?? [])" class="promocode-info__step">
                <div>{{ step }}</div>
            </div>
        </div>
    </form>
</template>

<style lang="scss" scoped>
form {
    width: 100%;
}

.promocode-info {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    background-color: #F6F6F6;
    line-height: 1.1;

    &__description {
        padding: 10px;
        color: $primary;
        font-size: 16px;
        line-height: 1.1;
    }

    &__step {
        position: relative;
        padding: 18px;
        padding-left: 40px;
        color: #B6B6B6;
        font-size: 14px;

        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 16px;
            width: 20px;
            height: 20px;
            border-radius: 100px;
            border: 1px solid #DDD;
        }
    }

    >div {
        border-top: 1px solid #DDD;
    }
}

.promocode-input {
    position: relative;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    font-size: 12px;

    @media screen and (max-width: 400px) {
        margin: 0;
    }

    &.with-info {
        input {
            border-radius: 10px 10px 0 0;
        }

        .promocode-input__trash {
            border-radius: 10px 10px 0 0;
        }
    }

    input {
        position: relative;
        margin-right: -20px;
        padding-right: 24px;
        border: none;
        border-radius: 10px;
        background-color: #F6F6F6;

        &:focus,
        &:active {
            border: none;
        }
    }

    &__button {
        position: relative;
        display: flex;
        border-radius: 10px;
        background: #D3D3D3;
        padding: 10px 18px 11px 19px;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;

        color: #212529;

        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;

        user-select: none;
        cursor: pointer;

        transition: all 0.2s;

        &:hover {
            background-color: darken(#D3D3D3, 4);
            transition: all 0.2s;
        }

        &.active {
            background-color: $primary;
            color: #fff;
        }
    }

    &__trash {
        position: relative;
        display: flex;
        border-radius: 10px;
        padding: 5px 14px;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        background-color: #F6F6F6;
        color: #000;

        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;

        user-select: none;
        cursor: pointer;

        transition: all 0.2s;

        &:hover {
            transition: all 0.2s;
        }
    }
}
</style>